.logo-container {
    display: flex;
    align-items: center;
    height: 100%;
    width: 200px;
    padding-right: 16px;
}

.logo-container button {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
}

.logo-image {
    width: 100%;
    height: 100%;
    min-height: 75px;
    object-fit: contain;
    transform-origin: left center;
    transform: scale(1.1);
    shape-rendering: geometricPrecision;
    text-rendering: geometricPrecision;
    image-rendering: optimizeQuality;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}