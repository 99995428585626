.testimonial-carousel {
  position: relative;
  padding: 2rem 1rem;
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0));
  backdrop-filter: blur(0px);
  border-radius: 8px;
  width: 100%;
  max-width: 1200px;
  margin: 2rem auto;
  z-index: 10;
}

.testimonial-content {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  min-height: auto;
}

.testimonial-text {
  font-style: italic;
  line-height: 1.8;
  margin-bottom: 2rem;
  max-width: 90%;
}

.author-info {
  text-align: center;
  width: 100%;
  padding: 1rem 0;
}

.author-info h3 {
  margin: 0.5rem 0;
  font-size: 1.25rem;
  line-height: 1.5;
  font-weight: 600;
}

.author-info p {
  margin: 0.5rem auto;
  font-size: 1rem;
  line-height: 1.5;
  max-width: 80%;
}

.testimonial-dots {
  padding: 1rem 0;
}

@media (max-width: 768px) {
  .testimonial-carousel {
    padding: 1rem 0.75rem;
    margin: 1rem auto;
  }

  .testimonial-content {
    padding: 0.75rem;
  }

  .author-info p {
    max-width: 95%;
  }
}