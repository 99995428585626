.heroContainer {
  position: relative;
  height: calc(50vh + 96px); /* Added header height */
  overflow: hidden;
  padding-top: 96px; /* Added padding for header */
  min-height: 700px; /* Ensure minimum height for content */
}

.videoBackground {
  position: absolute;
  min-width: 100%;
  min-height: 100%;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

.contentContainer {
  position: relative;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: white;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
  padding-top: 86px;
}

.title {
  font-size: clamp(2rem, 4vw, 3.5rem);
  font-weight: bold;
  margin-bottom: 1rem;
  line-height: 1.2;
}

.subtitle {
  font-size: clamp(1.5rem, 3vw, 2rem);
  margin-bottom: 1.5rem;
  line-height: 1.3;
}

.description {
  max-width: 800px;
  font-size: 1.1rem;
  line-height: 1.6;
  background: rgba(45, 27, 105, 0.15);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 16px;
  padding: clamp(25px, calc(1.5rem + 2vw), 45px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.2);
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.1);
  font-weight: 400;
  letter-spacing: 0.3px;
  margin-bottom: clamp(30px, 5vh, 50px);
  overflow: visible;
  height: auto;
  min-height: fit-content;
}

@media (max-width: 768px) {
  .heroContainer {
    height: auto;
    min-height: 100vh;
    padding-bottom: 2rem;
  }

  .contentContainer {
    padding: 2rem;
    padding-top: 120px;
    justify-content: flex-start;
  }

  .title {
    font-size: clamp(1.8rem, 5vw, 2.5rem);
  }

  .subtitle {
    font-size: clamp(1.2rem, 4vw, 1.5rem);
  }

  .description {
    font-size: 1rem;
    padding: clamp(20px, calc(1rem + 2vw), 35px);
    margin-bottom: clamp(25px, 4vh, 40px);
  }
}