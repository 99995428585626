/* Blinking cursor animation */
@keyframes blink {
  0%, 100% { opacity: 1; }
  50% { opacity: 0; }
}

.animate-blink {
  animation: blink 1s infinite;
}

/* 3D perspective effect */
.perspective-text {
  perspective: 1000px;
  transform-style: preserve-3d;
}

/* Gradient animation */
@keyframes gradient {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.animate-gradient {
  animation: gradient 8s linear infinite;
  background-size: 300% 300%;
}