.hero-section {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}

.video-element {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.content-overlay {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.6)
  );
  padding: 1rem;
  overflow-y: auto;
}

.text-content {
  text-align: center;
  max-width: 1200px;
  width: 100%;
  margin-top: clamp(180px, 12vh, 120px);
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.hero-text-container {
  margin-bottom: 1rem;
  font-weight: 700;
  line-height: 1;
  letter-spacing: -0.02em;
}

.hero-text-container div {
  margin-bottom: 0.25rem;
}

.services-text {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1.25rem;
  line-height: 1.4;
}

.service-item {
  white-space: nowrap;
}

.service-separator {
  margin: 0 0.5rem;
  opacity: 0.7;
}

.cursor {
  display: inline-block;
  width: 2px;
  height: 1em;
  background-color: white;
  margin-left: 4px;
  vertical-align: middle;
}

.animate-blink {
  animation: blink 1s step-end infinite;
}

@keyframes blink {
  50% {
    opacity: 0;
  }
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}

.modern-gradient-button {
  background-size: 200% auto;
  animation: gradientShift 8s ease infinite;
  transition: all 0.3s ease;
  margin: 0.5rem 0;
}

.modern-gradient-button:hover {
  animation-play-state: paused;
  transform: translateY(-2px);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .content-overlay {
    padding: 1rem;
  }

  .text-content {
    margin-top: clamp(60px, 10vh, 100px);
    gap: 0.75rem;
  }

  .hero-text-container {
    font-size: 3rem;
  }

  .services-text {
    font-size: 1rem;
  }
}

/* Custom scrollbar for overflow content */
.hero-section::-webkit-scrollbar {
  width: 6px;
}

.hero-section::-webkit-scrollbar-track {
  background: rgba(255, 255, 255, 0.1);
}

.hero-section::-webkit-scrollbar-thumb {
  background: rgba(255, 255, 255, 0.3);
  border-radius: 3px;
}

.hero-section::-webkit-scrollbar-thumb:hover {
  background: rgba(255, 255, 255, 0.4);
}