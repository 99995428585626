@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;600;700;800&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Outfit:wght@300;400;500;600;700&display=swap');
@import "react-calendar/dist/Calendar.css";
@tailwind base;
@tailwind components;
@tailwind utilities;

/* Move the import statement to the top */

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@layer utilities {
  .perspective {
    perspective: 1000px;
  }

  .transform-style-3d {
    transform-style: preserve-3d;
  }

  .rotate-y-10 {
    transform: rotateY(10deg);
  }
}

/* Button Animation Styles */
@keyframes gradientShift {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

.modern-gradient-button {
  background-size: 200% auto;
  animation: gradientShift 8s ease infinite;
}

.modern-gradient-button:hover {
  animation-play-state: paused;
}